<template>
    <div class="U000002-template1">
        <div class="video-box">
            <video ref="localVideo" autoplay="autoplay" muted loop="loop"
                :src="video_url">
                
            </video>
        </div>
      
    </div>
</template>

<script>
// https://www.npmjs.com/package/vue-awesome-swiper
export default {
    props: ['id', 'datas', 'styles'],
    data () {
        const self = this;
        return {
            falses: false,
            defaultUrl:''
        };
    },


    computed: {
        // 初始化
        // swiper () {
        //     return this.$refs.mySwiper.swiper;
        // },

	    /** 样式 */
        video_url() {
            // console.log(this.datas.text,22)
            return this.datas.video_url || '视频';
        },

    },
    beforeRouteLeave (to, from, next) {
     this.targetName = to.name  // 提示框点击确认后跳转的 路由
       console.log(this.$refs.localVideo,444)
        next()
    },

    methods: {
    },

    mounted () {
        this.$emit('loaded');
    }
};
</script>

<style lang="less">
    

</style>

<style lang="less" scoped>
    video{
        width: 100%;
    }
    .video-box{
        width: 100%;
        background: #000;
    }
    .U000002-template1 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 100%;
        overflow: hidden;
        .list-image{
            overflow: hidden;
        }
    }

</style>
