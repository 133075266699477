<template>
    <div class="U000002-template1">
        <div class="header-box">
            <div v-if="image_url=='nothing'" class="nopicture">未设置图片</div>
            <div v-else>
                
            
                <div class="header-con" @click="closepop">
                    <img :src="image_url">
                </div>
                <div class="header-pop popText" :style="text_style" :hidden="pops">
                    <template v-for="(item, idx) in list">
                        <div class="header-item" :key="idx">
                            <img :src="item.icon0_url">
                        </div>
                    </template>
                </div>
            </div>
        </div>
      
    </div>
</template>

<script>
// https://www.npmjs.com/package/vue-awesome-swiper
export default {
    props: ['id', 'datas', 'styles','vdc'],
    data () {
        const self = this;
        return {
            falses: false,
            defaultUrl:'',
            pops: false
        };
    },


    computed: {
        // 初始化
        image_url() {
            return this.datas.image_url || 'nothing';
        },
        list () {
            try {
                let list = [...this.datas.list] || [];
                // list = list.filter(item => item.icon0url != '');

                return list;
            } catch (err) {
                return [];
            }
        },
        text_style() {
                const {
                    align,
                } = this.datas;
                return `
                ${align=='left'?'left:0':'right:0'};
            `;
            },
	    /** 样式 */
	    // wrapper_style() {
		   //  const {
			  //   height
		   //  } = this.datas;
		   //  return `
     //            color: red;
     //            width: 100%;
     //            height: ${this.entrance=='magic'?height:(height*0.59)}px;
     //        `;
	    // },
     //    carousel_style() {
     //        const {
     //            height
     //        } = this.datas;
     //        var num = this.entrance=='magic'?height:(height*0.59);
     //        // console.log(this.entrance,9999000,num)
     //        return num.toString();
     //    },
    },

    methods: {
        closepop(){
            this.pops = !this.pops;
        }
    },

    mounted () {
        // console.log(this.vdc,54500)
        this.$emit('loaded');
    }
};
</script>

<style type="text/css" scoped="">

    .header-box{
        width: 100%;
        position: relative;
        /*height: 60px;*/
     /*   position: absolute;
        top: 42px;*/
    }
    .header-con{
        width: 100%;
        height: 36px;
        overflow: hidden;
    }
    .header-con img{
        width: auto;
        height: 36px;
        margin: auto;
        /*object-fit: cover;*/
    }

    .header-pop{
        width: 118px;
        position: absolute;
        /*left: 0;*/
        /*border: 1px solid red;*/
    }
    .header-pop img{
        width: auto;
        height: 30px;
    }

    .nopicture{
        width: auto;
        height: 60px;
        line-height: 60px;
    }


</style>

<style lang="less" scoped>
    .U000002-template1 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        
    }

</style>


