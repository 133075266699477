<template>
    <div class="U000002-template1">
        <img v-if="list.length == 0" :style="wrapper_style" :src="defaultUrl" alt="">
        <div v-if="list.length == 1" :style="wrapper_style">
            <div class="list-l-img" :style="{background: 'url('+list[0].image_url+')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'}">
                    
            </div>
        </div>
        <el-carousel 
            :height="(datas.height * 0.59)+'px'"
            v-if="list.length > 1">
          <el-carousel-item v-for="(item, idx) in list" :key="idx">
            <div class="list-image" v-if="item.image_url" :style="wrapper_style">
                <div class="list-l-img" :style="{background: 'url('+item.image_url+')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'}">
                        
                </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      
    </div>
</template>

<script>
// https://www.npmjs.com/package/vue-awesome-swiper
export default {
    props: ['id', 'datas', 'styles'],
    data () {
        const self = this;
        return {
            falses: false,
            defaultUrl:''
        };
    },


    computed: {
        // 初始化
        // swiper () {
        //     return this.$refs.mySwiper.swiper;
        // },

	    /** 样式 */
	    wrapper_style() {
		    const {
			    height
		    } = this.datas;
		    return `
                width: 100%;
                height: ${height * 0.59}px;
            `;
	    },

        // 广告轮播列表
        list () {
            try {
                let list = [...this.datas.list] || [];
                list = list.filter(item => item.image != '');
                return list;
            } catch (err) {
                return [];
            }
        }
    },

    methods: {
    },

    mounted () {
        this.$emit('loaded');
    }
};
</script>

<style lang="less">
    

</style>

<style lang="less" scoped>
    .U000002-template1 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        overflow: hidden;
        .list-image{
            overflow: hidden;
        }
         .list-l-img{
            width: 100%;
            height: 100%;

        }
    }

</style>
