<template>
	<section>
		<div class="banner">
			<div class="banner-con">
				<el-input v-model="input" placeholder="请输入模版名称关键词"></el-input>
				<i class="iconfont icon-sousuo"></i>
			</div>
		</div>
		
		<div class="section">
			<div class="section-content">
				<div class="top">
					<div class="top-left">
						<div class="top-left-title">行业筛选：</div>
						<div class="top-l-l">
							<div :class="{active: 0==topn}" @click="topc_f(0,0)">全部</div>
							<div :class="{active: index+1==topn}" v-for="(val,index) in tradedata" :key="'tra'+index" @click="topc_f(index+1,val[0])">{{val[1]}}</div>

						</div>
						<!-- <div class="top-l-r" @click="all">{{all_in?'收起':'展开全部'}}</div> -->
					</div>
				</div>



				<div class="case-box">
					<div class="case-item" v-for="(val,index) in listdata" :key="'list'+index">
						<div class="phone">
							<!-- {{val.list}} -->
							<magic :item="val.list"></magic>
						</div>
						<p class="pagename">{{val.name}}</p>
					</div>
				</div>
			</div>
		</div>
		
	</section>
</template>
<script type="text/javascript">
	import magic from '@/components/magic/magic.vue'
	export default {
    data(){
        return {
        	// trade_all_data:[],
        	// trade_5:[],

        	tradedata:[],
        	topn: 0,
        	all_in: false,


        	postdata:{
        		start: 1,
        		count: 12,
        		modeid: 0,
        	},
        	listdata:[],
        	input:''
        }
    },
    components:{
    	magic
    },
	mounted(){
		this.trade();
		this.list_page();

	},
	methods: {
		topc_f(index,id){
			this.topn = index;
			this.postdata.modeid = id;
			this.postdata.start = 1;
			this.listdata = [];
			this.list_page();
		},
		all(){
			// this.all_in = !this.all_in;
			// if(this.all_in){
			// 	this.tradedata = this.trade_all_data;
			// }else{
			// 	this.tradedata = this.trade_5;

			// }
		},
		trade(){
			let _this = this;
			this.axios.post('/cloud/nologin/get_trade')
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {
                    _this.$nextTick(function () {
                        // _this.tradedata = res.extra;
                        var list = res.extra;
                        var entries = Object.entries(list);
                        _this.tradedata = entries;

                    })
                    
                } else {
                    _this.logining = false;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		},

		list_page(){
			let _this = this;
			this.axios.post('/cloud/nologin/list_page',{
				list: this.postdata
			})
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {
                    _this.$nextTick(function () {
                        _this.listdata = res.extra.list;    
                        var arr = res.extra.list;
                        arr.map((item,index) => {
                        	item.list = [];
							_this.list_item(item.id,index);
						});

                    })
                    
                } else {
                    _this.logining = false;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		},
		list_item(id,index){
			let _this = this;
			this.axios.post('/cloud/nologin/detail_page',{
				pageid : id
			})
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {
                    _this.$nextTick(function () {
                    	_this.listdata[index].list = res.extra.detail;
                    	// console.log(_this.listdata)
                    	_this.$set(this.listdata,index,_this.listdata[index])
			                    	// _this.$set(this.listdata[index],'list',res.extra.detail)

                    })
                    
                } else {
                    _this.logining = false;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		}
		// 

	},
  
  // router
}

</script>
<style type="text/css" scoped>
	.banner{
		width: 100%;
		height: 192px;
		background-image: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/b3666.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.banner-con{
		width: 590px;
		height: 42px;
		color: #333333;
		display: flex;
		align-items: center;
		background-color: #fff;
		border-radius: 49px;
		padding: 0 24px;
	}
	.banner-con i{
		font-size: 22px;
		color: #333333;
		font-weight: bold;
	}
	/deep/.banner-con .el-input__inner{
		border: none;
	}
	.top{
		width: 100%;
		display: flex;
		align-items: center;
		margin-top: 40px;
		padding: 0 100px;
	}
	.top-left{
		width: 100%;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
	}
	.top-left-title{
		height: 30px;
		line-height: 30px;
		flex-shrink: 0;
		font-size: 18px;
		font-weight: bold;
		margin-top: 6px;
		margin-right: 20px;
	}
	.top-l-l{
		/*width: 100%;*/
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.top-l-l div{
		min-width: 80px;
		text-align: center;
		flex-shrink: 0;
		height: 30px;
		line-height: 30px;
		font-size: 13px;
		color: #424141;
		padding: 0 15px;
		margin-right: 8px;
		cursor: pointer;
		margin-bottom: 6px;
		margin-top: 6px;
		font-weight: 300;
	}
	.top-l-l div.active{
		border-radius: 4px;
		border: 1px solid #E79E0D;
		color: #E79E0D;
	}
	.top-l-r{
		width: 88px;
		text-align: center;
		flex-shrink: 0;
		height: 30px;
		line-height: 30px;
		font-size: 13px;
		color: #169BD5;
		position: relative;
		margin-left: auto;
	}
	.top-l-r::before{
		content: '';
		width: 1px;
		height: 22px;
		background-color: #ACABAB;
		position: absolute;
		top: 4px;
		left: 0;
	}
	

	.case-box{
		width: 100%;
		display: flex;
		/*justify-content: space-between;*/
		flex-wrap: wrap;
		margin-top: 80px;
	}
	.case-item{
		width: 20.5%;
		height: 480px;
		/*background: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/mobile.svg);*/
		background: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/mobilebg1.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		/*overflow: hidden;*/
		margin-bottom: 100px;
		margin-right: 6%;
	}
	.case-item:nth-child(4n){
		margin-right: 0;
	}
	.phone{
		width: 100%;
		height: 418px;
		border-radius: 6px;
		overflow-y: auto;
		/*background-color: #fff;*/
		margin-left: auto;
		margin-right: auto;
		margin-top: 36px;
		padding: 0 12px;

	}
	.pagename{
	  	text-align: center;
	  	font-size: 14px;
	  	color: #333;
	  	margin-top: 50px;
	}
	.phone::-webkit-scrollbar {
	  /*滚动条整体样式*/
	  width : 0;  /*高宽分别对应横竖滚动条的尺寸*/
	  height: 1px;
	  }
	  .phone::-webkit-scrollbar-thumb {
	  /*滚动条里面小方块*/
	  border-radius   : 10px;
	  background-color: skyblue;
	  background-image: -webkit-linear-gradient(
	      45deg,
	      rgba(255, 255, 255, 0.2) 25%,
	      transparent 25%,
	      transparent 50%,
	      rgba(255, 255, 255, 0.2) 50%,
	      rgba(255, 255, 255, 0.2) 75%,
	      transparent 75%,
	      transparent
	  );
	  }
	  .phone::-webkit-scrollbar-track {
	  /*滚动条里面轨道*/
	  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
	  background   : #ededed;
	  border-radius: 10px;
	  }
</style>