<template>
 	 <div class="component-wrapper u000242">
 	 	<div class="component-header">
 	 		<i class="iconfont icon-ketuozhuai"></i>
	    	<div class="title">{{ title }}</div>
    	</div>

  	</div>
</template>

<script>
	export default {
		props: ['title'],

		
	};
</script>

<style lang="less" scoped>
.component-header{
	width: 100%;
	height: 42px;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: bold;
	color: #333;
	position: absolute;
	.title{
		padding-left: 12px;
	}
}
</style>
