<template>
    <div class="U000002-template1">
        <div>
            <!-- <el-image
                :src="image_url"
                fit="contain">
                    <div slot="error" class="image-slot">{{image_url}}</div>
            </el-image> -->
            <img :src="image_url" :alt="image_url">
        </div>
      
    </div>
</template>

<script>
// https://www.npmjs.com/package/vue-awesome-swiper
export default {
    props: ['id', 'datas', 'styles'],
    data () {
        const self = this;
        return {
            falses: false,
            defaultUrl:''
        };
    },


    computed: {
        // 初始化
        // swiper () {
        //     return this.$refs.mySwiper.swiper;
        // },

	    /** 样式 */
	    // wrapper_style() {
		   //  const {
			  //   height
		   //  } = this.datas;
		   //  return `
     //            width: 100%;
     //            height: ${height}px;
     //        `;
	    // },
        image_url() {
            // console.log(this.datas.text,22)
            return this.datas.image_url || '图片';
        },

    },

    methods: {
    },

    mounted () {
        this.$emit('loaded');
    }
};
</script>

<style lang="less">
    

</style>

<style lang="less" scoped>
    .U000002-template1 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 100%;
        overflow: hidden;
        .list-image{
            overflow: hidden;
        }
    }

</style>
