var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"U000002-template1"},[(_vm.list.length == 0)?_c('img',{style:(_vm.wrapper_style),attrs:{"src":_vm.defaultUrl,"alt":""}}):_vm._e(),(_vm.list.length == 1)?_c('div',{style:(_vm.wrapper_style)},[_c('div',{staticClass:"list-l-img",style:({background: 'url('+_vm.list[0].image_url+')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'})})]):_vm._e(),(_vm.list.length > 1)?_c('el-carousel',{attrs:{"height":(_vm.datas.height * 0.59)+'px'}},_vm._l((_vm.list),function(item,idx){return _c('el-carousel-item',{key:idx},[(item.image_url)?_c('div',{staticClass:"list-image",style:(_vm.wrapper_style)},[_c('div',{staticClass:"list-l-img",style:({background: 'url('+item.image_url+')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'})})]):_vm._e()])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }