<template>
    <div class="U000002-template1">

        <div class="box" :style="wrapper_style">
            <div class="list-image" :style="wrapper_style" v-for="(item, idx) in list" :key="idx">
                <img class="list-l-img" :src="item.image_url">
            </div>
            
        </div>
    </div>
</template>

<script>
// https://www.npmjs.com/package/vue-awesome-swiper
export default {
    props: ['id', 'datas', 'styles'],
    data () {
        const self = this;
        return {
            falses: false,
            defaultUrl:''
        };
    },


    computed: {
        // 初始化
        // swiper () {
        //     return this.$refs.mySwiper.swiper;
        // },
        /** 样式 */
        wrapper_style() {
            const {
                height
            } = this.datas;
            return `
                color: red;
                width: 100%;
                
            `;
            // height: ${this.entrance=='magic'?height:(height*0.59)}px;
        },

        // 广告轮播列表
        list () {
            try {
                let list = [...this.datas.list] || [];
                list = list.filter(item => item.image != '');
                return list;
            } catch (err) {
                return [];
            }
        }
    },

    methods: {
    },

    mounted () {
        this.$emit('loaded');
    }
};
</script>

<style lang="less">
    

</style>

<style lang="less" scoped>
    .U000002-template1 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        overflow: hidden;
        .box{
            display: flex;
            align-items: center;
        }
        .list-image{
            overflow: hidden;
        }
        .list-l-img{
            width: 100%;
            height: 100%;
            object-fit: cover;

        }
    }

</style>
