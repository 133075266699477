<template>
   <div class="root">
     <!-- <SortableList lockAxis="y" v-model="items">
       <SortableItem v-for="(item, index) in items" :index="index" :key="index" :item="item"/>
     </SortableList> -->
    <template v-for="(val, index) in items" :index="index">
        <component
            :key="index"
            :is="'U_'+ val.mode"
            :datas="val"
            @errored="after_componnet_errored"
            @loaded="after_componnet_loaded">
            <slot></slot>
        </component>
        
    </template>

    
 


   </div>
</template>
 
<script>
    // 所有控件
    import {
        U_text,
        U_video,
        U_banner,
        U_image,
        U_linker,
        U_info,
        U_header,
        U_footer
    } from './magic.js';
export default {
    props: ['item'],
    data () {

        return {
            module: null, // 组件模块
            
        }
    },
    components:{
        U_text,
        U_video,
        U_banner,
        U_image,
        U_linker,
        U_info,
        U_header,
        U_footer

    },
    computed: {

        // 广告轮播列表
        items () {
            return this.item;
        }
    },
    created () {

        // console.log(this.uikey)
        this.module = () => ({
            // 需要加载的组件 (应该是一个 `Promise` 对象)
            
            // component: import(`/ui-component/${this.id}.vue`),
            // 异步组件加载时使用的组件
            // loading: LoadingComponent,
            // 加载失败时使用的组件
            error: ErrorComponent,
            // 展示加载时组件的延时时间。默认值是 200 (毫秒)
            // delay: 200,
            // 如果提供了超时时间且组件加载也超时了，
            // 则使用加载失败时使用的组件。默认值是：`Infinity`
            // timeout: 3000
        });
        // console.log(this.module)
    },
    methods: {
        /**
         * 当子组件加载完毕
         */
        after_componnet_loaded () {

        },
        /**
        * 当子组件加载失败
        */
        after_componnet_errored() {
            this.$emit('reload');
        }
    },
}
</script>
<style type="text/css" scoped>
    .phone-list{
        width: 100%;
        position: relative;
    }
    .text{
        font-size: 16px;
        color: #333;
        word-break:break-all;
    }
    .img{
        width: 100%;
        text-align: center;
    }
    .video{
        width: 100%;
    }
    .carousel{
        margin-bottom: 6px;
    }
    .carousel img{
        width: 100%;
        height: auto;
    }
    .operation{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
        background-color: rgba(181, 178, 174, 0.66);
        position: absolute;
        top:0;
        left: 0;
        display: none;
    }
    .operation i{
        font-size: 18px;
        color: #fff;
        margin: 0 4px;
        cursor: pointer;
    }
    .operation i:hover{
        color: red;
    }
    /*.phone-list:hover .operation{
        display: flex;
    }*/
    
</style>