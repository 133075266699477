<template>
 	 <div class="component-wrapper u000242">
    	<div :style="text_style" class="title">{{ text }}</div>
  	</div>
</template>

<script>
	export default {
		props: ['datas'],

		computed: {
			/** 样式 */
			text_style() {
				const {
					align,
					size,
					bold,
					colour,
					paddingv,
					paddingh
				} = this.datas;
				return `
                width: 100%;
                text-align: ${align};
                font-size: ${size}px;
                color: ${colour};
                padding: ${paddingv}px ${paddingh}px;
                font-weight: ${bold==1?'bold':''};
            `;
			},
			/** 标题 */
			text() {
				// console.log(this.datas.text,22)
				return this.datas.text || '文本内容';
			},

		},

		mounted() {
			this.$emit('loaded');
		}
	};
</script>

<style lang="less" scoped>
	.title{
		white-space: pre-wrap;
	}
</style>
