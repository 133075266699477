<template>
    <div class="U000002-template1">
        <div class="foot">
            <template v-for="(item, idx) in list">
                <!-- {{item}} --> 
                <div class="foot-item"  @click="choose_f(idx)" :key="idx">
                    <el-image
                    :src="navindex==idx?item.icon1_url:item.icon0_url"
                    fit="contain"></el-image>
                </div>
            </template>
            
        </div>
      
    </div>
</template>

<script>
// https://www.npmjs.com/package/vue-awesome-swiper
export default {
    props: ['datas'],
    data () {
        const self = this;
        return {
            falses: false,
            defaultUrl:'',
            navindex: 0
        };
    },


    computed: {
        // 初始化
        // swiper () {
        //     return this.$refs.mySwiper.swiper;
        // },

	    /** 样式 */
        // 广告轮播列表
        list () {
            try {
                let list = [...this.datas.list] || [];
                // list = list.filter(item => item.icon0url != '');

                return list;
            } catch (err) {
                return [];
            }
        }
    },

    methods: {
        choose_f(idx){
            this.navindex = idx;
        }
    },

    mounted () {
        this.$emit('loaded');
    }
};
</script>

<style lang="less">
    

</style>

<style lang="less" scoped>
    .U000002-template1 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 100%;
        overflow: hidden;

    }
    
    .foot{
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-around;;
        .foot-item{
            width: 100%;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .el-image{
                width: 36px;
                height: 36px;
                border-radious: 100%;
            }
            p{
                font-size: 12px;
                padding-top: 4px;
            }
        }
    }

</style>
